import { useMutation } from '@tanstack/vue-query';
import { defineStore } from 'pinia';
import { ResponseData } from '~/composables/types/RequiredDocument';

export const useAdvanceReportStore = defineStore(
  'advance-report',
  () => {
    const getReports = () => {
      return $fetch<ResponseData<{ id: string; name: string }>>(`/assistance-requests/advance-filter-store`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const saveReport = (reportId: string | undefined) => {
      return useMutation((body: { name: string; selectedColumns: string[] }) =>
        $fetch<ResponseData<any>>(`/assistance-requests/advance-filter-store${reportId ? '/' + reportId : ''}`, {
          ...globalFetchOptions(),
          method: reportId ? 'put' : 'post',
          body,
        })
      );
    };

    const deleteReport = (reportId: string | undefined) => {
      return useMutation((body: {}) =>
        $fetch<ResponseData<any>>(`/assistance-requests/advance-filter-store${reportId ? '/' + reportId : ''}`, {
          ...globalFetchOptions(),
          method: 'delete',
        })
      );
    };

    const formOptions = ref<string[]>([]);

    return {
      getReports,
      saveReport,
      formOptions,
      deleteReport,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
);
