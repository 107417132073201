<template>
  <header class="print:hidden">
    <div class="bg-blue-primary border-b-2 border-red-500">
      <div class="mx-auto px-2 sm:px-4 lg:px-8">
        <div class="flex h-16 justify-between">
          <LayoutHeaderLogo />
          <div class="ml-4 flex items-center font-proxima text-white text-sm font-semibold">
            <LayoutHeaderProfile />
          </div>
        </div>
      </div>
    </div>
    <LayoutHeaderMenu />
    <LayoutReports />
  </header>
</template>
<script setup lang="ts">
</script>
