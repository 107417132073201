<template>
  <AdminReportsListModal v-if="reportOpen" :open="reportOpen" @close="reportOpen = false" />
</template>
<script setup lang="ts">
const adminStore = useAdminStore();
const {reportOpen} = storeToRefs(adminStore);
onMounted(() => {
  reportOpen.value = false;
});
</script>
