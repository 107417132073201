<template>
  <button @click="open = true">
    <VTooltip title="Edit">
      <icon name="material-symbols:edit" class="h-6 w-6" />
    </VTooltip>
    <AdminReportsFormModal v-if="open" :report="report" :open="open" @close="open = false" />
  </button>
</template>
<script setup lang="ts">
const open = ref(false);
const props = defineProps<{
  report: any
}>()
</script>
