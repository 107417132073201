<template>
  <button
      class="font-proxima text-sm font-semibold text-white border border-blue-primary bg-blue-primary active:bg-blue-800 hover:bg-blue-800 rounded-md px-6 py-2 mr-4"
      type="button"
      @click="openModal"
  >
    Save Report
    <AdminReportsReportNameModal :report="report" v-if="open" :open="open" @reportSaved="reportSaved" @close="open = false" />
  </button>
</template>
<script setup lang="ts">
import {useAdvanceReportStore} from "~/composables/stores/AdvanceReportStore";

const open = ref(false);
interface Props {
  report?: any;
}
const props = defineProps<Props>();
const emit = defineEmits(['reportSaved']);

const store = useAdvanceReportStore()
const { formOptions } = storeToRefs(store)

const openModal = () => {
  if (formOptions.value.length === 0) {
    toastError('', 'Please select at least one option to save the report')
    return;
  }
  open.value = true
}

const reportSaved = () => {
  open.value = false
  emit('reportSaved')
}
</script>
